import React from 'react';
import Head from 'next/head';
import getSEO from '@ui/utils/getSEO';
import { SEOProps } from './SEO.types';

const DOMAIN = process.env.DOMAIN ?? 'https://www.livelyme.com';

export const SEO = ({ page = {}, enableAntiFlicker }: SEOProps) => {
  const { seo } = page ?? {};
  const metatags = getSEO(seo);
  return (
    <Head>
      <link
        rel="canonical"
        href={(seo?.canonical?.value || `${DOMAIN}${page?.path}`).toLowerCase()}
        key="link-canonical"
      />
      {metatags?.map((tag) => (
        <meta key={tag.name || tag.property} {...tag} />
      ))}
      {seo?.title ? <title>{seo?.title?.value}</title> : null}
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta name="contentful_space" content={process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID} />
      <meta name="contentful_environment" content={process.env.NEXT_PUBLIC_CONTENTFUL_ENV} />
    </Head>
  );
};

export default SEO;
